import { Outlet, useLoaderData, useNavigate } from '@remix-run/react';
import { loggedInUser } from '../loaders/loggedInUser';
import { Button } from '../components/Button';
import { useCheckBrowser } from '../hooks/useCheckBrowser';
import { useEffect, useState } from 'react';
import { MainContainer } from '../components/MainContainer';
import { Box } from '../components/Box';
import { createLoader } from '../loaders/createLoader';
import { SIGN_UP } from '../analytics-events';

export const loader = createLoader(loggedInUser);

export default function IndexRoute() {
  const data = useLoaderData<typeof loader>();
  const [isInBrowser, hasChecked, isBrowserOk] = useCheckBrowser(false);
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const navigate = useNavigate();
  const isLoggedIn = data.isLoggedIn;
  const error = data.error;
  let errorComponent = undefined;

  useEffect(() => {
    if (!isInBrowser) {
      return;
    }

    if (hasChecked && isBrowserOk) {
      setIsHidden(false);
      return;
    }

    const url = new URL(window.location.toString());
    const host = url.host;
    window.location.assign(`https://${host}/test-capabilities`);
  }, [isInBrowser, hasChecked, isBrowserOk, navigate]);

  if (!errorComponent && error) {
    errorComponent = (
      <div className="mb-8 flex w-full justify-center">
        <div className="flex w-full max-w-2xl">
          <Box
            bgClassName="bg-rose-400 bg-opacity-50"
            borderClassName="border-rose-400"
          >
            <p className="text-rose-50">{error}</p>
          </Box>
        </div>
      </div>
    );
  }

  return (
    <MainContainer isLoggedIn={isLoggedIn} isHidden={isHidden}>
      <div className="p-2 xl:p-8">
        {errorComponent}
        <div className="flex w-full max-w-screen-2xl flex-col items-center justify-center gap-4 lg:flex-row">
          {/* This is a black square that'll hold a video
          <div className="w-full max-w-lg rounded-md border-4 border-cc-300 drop-shadow-md lg:border-8 xl:max-w-2xl">
            <div className="aspect-square bg-black"></div>
          </div>
          */}
          <div className="flex flex-col gap-5 lg:w-4/12">
            <Box>
              <h1 className="uppercase">
                <div className="text-3xl font-semibold">Creator Camp —</div>
                <div className="text-6xl font-bold">TextGen.</div>
              </h1>
            </Box>
            <Box>
              <div className="flex flex-col gap-4">
                <p>
                  Isn't it annoying to come up with creative and engaging titles
                  and descriptions for your YouTube videos?
                </p>
                <p className="text-xl">
                  Nope. Not anymore... Our app is here to help.
                </p>
              </div>
            </Box>
            <div className="flex flex-col items-center gap-4 lg:flex-row">
              <div className="flex-1 rounded-md border border-cc-200 bg-cc-300 bg-opacity-50 p-3 shadow-lg backdrop-blur-sm lg:aspect-square lg:w-7/12">
                <p>
                  Let us take the hassle out of YouTube content creation with
                  our app that generates attention-grabbing titles, accurate
                  transcriptions, and compelling descriptions for your videos.
                </p>
                {/* <p className="italic lg:text-sm">
                  Textgen is a game changer for YouTube creators. It generates
                  effective titles & descriptions in minutes, saving hours of
                  time & stress. Can't imagine going back to manual work. It
                  transformed my YouTube experience.
                </p>
                <div className="mt-1 text-right">- Name here</div> */}
              </div>
              <div className="flex aspect-square w-48 flex-col rounded-md border border-cc-200 bg-cc-400 bg-opacity-30 p-3 shadow-lg backdrop-blur-sm lg:w-5/12">
                <div>
                  <span className="text-4xl">$–</span>
                  <span> / Month</span>
                  <ul className="mt-2 list-inside list-disc">
                    <li>Titles</li>
                    <li>Descriptions</li>
                    <li>–</li>
                  </ul>
                </div>
                <div className="mt-4 flex h-12 w-full">
                  <Button
                    analyticsEvent={SIGN_UP}
                    type="success"
                    onClick={() => {
                      navigate('signup');
                    }}
                  >
                    Signup Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pointer-events-none fixed left-0 top-0 flex h-full w-full items-center justify-center">
        <div className="pointer-events-auto h-full">
          <Outlet />
        </div>
      </div>
    </MainContainer>
  );
}
